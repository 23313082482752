<template>
  <div class="class-content">
    <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
      <li class="nav-item" role="presentation">
        <button
          class="nav-link active"
          id="pills-scheduled-tab"
          data-bs-toggle="pill"
          data-bs-target="#pills-scheduled"
          type="button"
          role="tab"
          aria-controls="pills-scheduled"
          aria-selected="true"
        >
          Recordings
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          class="nav-link "
          id="pills-feedback-tab"
          data-bs-toggle="pill"
          data-bs-target="#pills-feedback"
          type="button"
          role="tab"
          aria-controls="pills-feedback"
          aria-selected="true"
        >
          Feedback
        </button>
      </li>
    </ul>
    <div class="tab-content" id="pills-tabContent">
      <div
        class="tab-pane fade show active"
        id="pills-scheduled"
        role="tabpanel"
        aria-labelledby="pills-scheduled-tab"
      >
        <div class="row">
          <webinar-loader v-if="recordingLoader == true"></webinar-loader>
          <NestRecordingCard
            v-else
            v-for="(recording, recordingIndex) in recordings"
            :key="recordingIndex"
            :recording="recording"
          />
          <div
            class="empty"
            v-if="recordings.length == 0 && recordingLoader == false"
          >
            No recording found
          </div>
        </div>
      </div>
      <div
        class="tab-pane fade s"
        id="pills-feedback"
        role="tabpanel"
        aria-labelledby="pills-feedback-tab"
      >
        <div class="row">
          <webinar-loader v-if="feedBackLoader == true"></webinar-loader>
          <div class="discussion-content" v-else>
            <div class="discussion-wrap">
              <div class="__body">
                <ul class="list-unstyled comment-group">
                  <li
                    v-for="(feedback, feedbackIndex) in feedbacks"
                    :key="feedbackIndex"
                  >
                    <a class="discussion-card card-hero">
                      <div class="thumbnail">
                        <img :src="feedback.image" alt="" />
                      </div>
                      <div class="_card-body row">
                        <div class="discuss-body col-md-12">
                          <div class="header">
                            <h6>
                              {{ feedback.first_name }} {{ feedback.last_name }}
                            </h6>
                            <p class="date">{{ feedback.created_on }}</p>
                          </div>
                          <!-- <p class="status">Latest reply from @Tom 2min ago</p> -->
                          <p class="body">
                            {{ feedback.feedback }}
                          </p>
                        </div>
                      </div>
                    </a>
                  </li>
                </ul>
                <div class="input-wrapper">
                  <div class="form-floating">
                    <textarea
                      class="form-control"
                      v-model="feedbackMessage"
                      placeholder="Leave a comment here"
                      id="floatingTextarea2"
                      style="height: 100px"
                    ></textarea>
                    <label for="floatingTextarea2">Feedback</label>
                  </div>
                  <div v-if="errors && errors.feedback" class="error-msg">
                    {{ errors.feedback[0] }}
                  </div>
                  <div class="btn-wrap mt-3">
                    <button
                      class="cta-primary btn m-auto"
                      @click.prevent="saveFeedback"
                      :disabled="isSubmitting == true"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NestRecordingCard from "@/components/NestRecordingCard.vue";
import errorLog from "@/errorLog";
import WebinarLoader from "@/components/loaders/WebinarLoader";
import NestEventService from "@/services/NestEventService.js";

export default {
  name: "Recordings",
  components: {
    NestRecordingCard,
    WebinarLoader
  },
  data() {
    return {
      recordings: [],
      recordingLoader: true,
      feedBackLoader: true,
      errorMessage: "No recordings found ",
      feedbacks: [],
      feedbackMessage: "",
      isSubmitting: false
    };
  },
  created() {
    this.getRecordings(0);
    this.getFeedback(0);
  },
  computed: {
    nestMember() {
      console.log(this.$store.getters["privilege/isNestMember"]);
      return this.$store.getters["privilege/isNestMember"];
    }
  },
  watch: {
    nestMember: function() {
      if (this.$store.getters["privilege/isNestMember"] == false) {
        this.$toast.error("No permission to access this page ");
        this.$router.push({ name: "dashboardTab" });
      }
    }
  },
  methods: {
    async getRecordings(page) {
      this.recordingLoader = true;
      const data = {
        page: page,
        schedule_id: this.$route.params.id
      };
      await NestEventService.getRecordings(data)
        .then(result => {
          if (result.data.status == "success") {
            this.recordingLoader = false;
            this.recordings = result.data.recordings;
            this.scrollTop();
          } else {
            this.recordingLoader = false;
            this.errorMessage = result.data.message;
          }
        })
        .catch(error => {
          this.recordingLoader = false;
          errorLog.log(error);
        });
    },
    getFeedback(page) {
      const data = {
        page: page,
        event_id: this.$route.params.id
      };
      NestEventService.getFeedback(data)
        .then(result => {
          if (result.data.status == "success") {
            this.feedBackLoader = false;
            this.feedbacks = result.data.feedback;
            this.scrollTop();
          } else {
            this.feedBackLoader = false;
            this.errorMessage = "No feedback found";
          }
        })
        .catch(error => {
          this.feedBackLoader = false;
          errorLog.log(error);
        });
    },
    saveFeedback() {
      if (this.feedbackMessage == "") {
        this.$toast.error("Please enter comment");
      } else {
        const data = {
          special_event_id: this.$route.params.id,
          feedback: this.feedbackMessage
        };
        this.isSubmitting = true;
        NestEventService.saveFeedback(data)
          .then(result => {
            this.isSubmitting = false;
            if (result.data.status == "error") {
              this.$toast.error(result.data.message);
            } else if (result.data.status == "validation_error") {
              this.errors = result.data.message || {};
            } else {
              this.getFeedback();
              this.feedbackMessage = "";
              this.$toast.success(result.data.message);
            }
          })
          .catch(error => {
            this.isLoading = false;
            errorLog.log(error);
          });
      }
    },
    scrollTop: function() {
      this.intervalId = setInterval(() => {
        if (window.pageYOffset === 0) {
          clearInterval(this.intervalId);
        }
        window.scroll(0, window.pageYOffset - 50);
      }, 20);
    }
  }
};
</script>

<style lang="scss">
@import "@/style/class-tab.scss";
@import "@/style/discussion-board.scss";
</style>
